<template>
  <div class="appeal tw-h-full tw-overflow-y-auto">
    <Header title="订单申诉" theme="light" step="/" />
    <div class="appeal__info ">
      <div class="appeal__info__row title">
        <div class="appeal__info__row__title">订单资讯</div>
      </div>
      <div class="appeal__info__row">
        <div class="appeal__info__row__title">买家昵称</div>
        <div class="appeal__info__row__content">{{ logData.userName }}</div>
      </div>
      <div class="appeal__info__row">
        <div class="appeal__info__row__title">卖家昵称</div>
        <div class="appeal__info__row__content">{{ logData.traderName }}</div>
      </div>
      <div class="appeal__info__row">
        <div class="appeal__info__row__title">下单时间</div>
        <div class="appeal__info__row__content">{{ logData.createdAt }}</div>
      </div>
      <div class="appeal__info__row" style="flex-wrap: wrap;">
        <div class="appeal__info__row__title">订单编号</div>
        <div class="appeal__info__row__content">{{ logData.orderNo }}</div>
      </div>
      <div class="appeal__info__row">
        <div class="appeal__info__row__title">支付方式</div>
        <!-- <img :src="channelImg" class="appeal__info__row__img" /> -->
        <div class="appeal__info__row__content">{{ account }}</div>
      </div>
      <div class="appeal__info__row">
        <div class="appeal__info__row__title">订单金额</div>
        <div class="appeal__info__row__content">{{ logData.orderAmount | thousands }} 元</div>
      </div>
    </div>
    <div class="appeal__select">
      <div class="appeal__select__title">申诉原因</div>
      <el-select v-model="appealOption" placeholder="请选择申诉原因" class="appeal__select__content">
        <el-option v-for="(item, index) in appealOptions" :key="index" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div>
    <div class="appeal__textarea">
      <div class="appeal__textarea__title">具体原因</div>
      <textarea v-model="appealContent" maxlength="100" placeholder="最多可输入100个字" class="appeal__textarea__content" />
    </div>
    <div class="appeal__upload">
      <div class="appeal__upload__title">上传截图</div>
      <div class="appeal__upload__grid">
        <div v-for="(item, index) in appealImages" :key="index" class="appeal__upload__grid__img">
          <div class="tw-relative">
            <img :src="item" />
            <div @click="onDeleteImageClicked(item)" class="  tw-text-[#ff7300] tw-absolute tw-right-0 tw-top-0">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="appeal__upload__grid__btn" @click="startCamera" v-if="appealImages.length < 3">
          <img src="@/assets/images/public/ic_add_m.png" />
        </div>
        <vue-image-cropper ref="camera" class="profile_camera" @postImg="uploadImage" />
      </div>
    </div>
    <div class="appeal__btns">
      <button class="btn" @click="postAppeal">发起申诉</button>
    </div>
    <input v-show="false" type="file" accept="image/*" ref="upload" @change="uploadChangeHandler" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Header from "@/components/Header.vue";
import moment from "moment";
import { createUUID } from "@/utils/UUID";
import VueImageCropper from "@/components/VueImageCropper";
export default {
  name: "Appeal",
  components: {
    Header,
    VueImageCropper,
  },
  data() {
    return {
      appealOption: "",
      appealContent: "",
      appealImages: [],
    };
  },
  computed: {
    ...mapState("orders", ["logData"]),
    ...mapState("dict", ["dict"]),
    account() {
      return this.logData.traderAccounts[0].channelName;
    },
    channelImg() {
      switch (this.account.channelCode) {
        case "bankcard":
          return require("@/assets/images/pay/ic_card_l.png");
        case "alipay":
          return require("@/assets/images/pay/ic_alipay_l.png");
        case "wechatpay":
          return require("@/assets/images/pay/ic_wechatpay_l.png");
        case "qq":
          return require("@/assets/images/pay/ic_qqpay_l.png");
        case "usdttrc":
          return require("@/assets/images/pay/ic_usdt_l_trc.png");
        case "usdterc":
          return require("@/assets/images/pay/ic_usdt_l_erc.png");
        default:
          return "";
      }
    },
    appealOptions() {
      let entries = Object.entries(this.dict.UserAppeal[0].data);
      let options = [];
      entries.forEach((item) => {
        options.push({
          value: item[0],
          label: item[1],
        });
      });
      return options;
    },
  },
  methods: {
    ...mapActions("orders", ["getLogId", "getUploadImgData"]),
    ...mapActions("appeals", ["addSellerAppeals", "addUserAppeals"]),
    ...mapActions("oss", ["getOssUrl", "uploadImg"]),
    onDeleteImageClicked(imageUrl) {
      console.log("onDeleteImageClicked");
      this.appealImages = this.appealImages.filter((str) => str !== imageUrl);
    },
    backHandler() {
      this.$router.go(-1);
    },
    startCamera() {
      this.$refs.camera.startCamera();
    },
    uploadImage(base64, fileName) {
      if (!base64 || !fileName) return;
      this.base64 = base64;
      this.fileName = fileName;
      console.log(fileName);

      // trader/ossUrl ? name="User_head" + "/" + new SimpleDateFormat("yyyy-MM-dd").format(new Date()) + "/" + UUID.randomUUID() + new File(path).getName();

      const path = {
        name: `Appeal_img/${this.fileName}`,
      };

      //1.使用 this.getUploadImgData GET 取得阿里云图片上传路径(fileUpUrl)
      this.getUploadImgData(path).then((res) => {
        if (res.code === "ok") {
          const url = res.data.fileUpUrl;
          const fileUrl = res.data.fileUrl;
          // 2.透过 this.updateOss PUT 进行图片上传
          this.updateOss(this.base64, url, fileUrl, res.data.contentType);
        }
      });
    },
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    },
    updateOss(base64, url, fileUrl, contentType) {
      console.log("updateOss");
      // base64转blob
      const blob = this.dataURLtoBlob(base64);
      // blob转arrayBuffer
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onload = (event) => {
        // arrayBuffer转Buffer
        const buffer = this.toBuffer(event.target.result);

        //上传图片到阿里云
        fetch(url, {
          method: "PUT",
          body: buffer,
          headers: new Headers({
            "Content-Type": contentType,
          }),
        })
          .then(() => {
            this.appealImages.push(fileUrl);
          })
          .catch(() => {
            this.showMessage("图像上传失败");
          });
      };
    },
    toBuffer(ab) {
      //ArrayBuffer ---> Buffer
      var buf = new Buffer(ab.byteLength);
      var view = new Uint8Array(ab);
      for (var i = 0; i < buf.length; ++i) {
        buf[i] = view[i];
      }
      return buf;
    },
    dataURLtoBlob(dataurl) {
      // base64转blob
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    upload() {
      this.$refs.upload.click();
    },
    uploadChangeHandler(e) {
      if (e.target.files.length <= 0) return;
      let file = e.target.files[0];
      this.updateImage(file);
      // let path = URL.createObjectURL(file)
      // console.log(path)
      // this.appealImages.push(path)
    },
    updateImage(file) {
      let date = moment().format("yyyy-MM-DD");
      let fileName = `Appeal_img/${date}/${createUUID()}${file.name}`;
      console.log(fileName);
      let self = this;
      this.getOssUrl({ name: fileName }).then((res) => {
        let data = {
          fileUpUrl: decodeURIComponent(res.data.fileUpUrl),
          contentType: res.data.contentType,
          file,
        };
        self.uploadImg(data).then(() => {
          self.appealImages.push(res.data.fileUrl);
        });
      });
    },
    postAppeal() {
      let data = {
        orderId: this.logData.id,
        orderNo: this.logData.orderNo,
        appealType: this.appealOption,
        appealDesc: this.appealContent,
        screenshot: this.appealImages.join(","),
      };
      console.log(data);
      console.log(data.screenshot.length);
      this.addUserAppeals(data).then((res) => {
        if (res.code === "ok") {
          this.showMessage(res.msg);
          this.$router.push(`/appealDetail?orderNo=${this.logData.orderNo}`);
        } else {
          this.showMessage(res.msg);
        }
      });
    },
    showMessage(message) {
      this.$message({
        message: message,
        iconClass: "x",
        center: true,
        duration: 1000,
        customClass: "error_message",
      });
    },
  },
  created() {
    this.getLogId(this.$route.params.orderId);
  },
};
</script>

<style lang="scss" scoped>
.appeal {
  padding: 0 20px 20px 20px;
  font-size: 14px;
  color: var(--color-font-heavy);
  overflow-y: scroll;
  height: 207vw;

  &__info {
    background-color: var(--color-info-background);
    border-radius: 10px;
    padding: 20px 0px;
    margin-bottom: 15px;

    &__row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      line-height: 26px;

      &.title {
        font-size: 13px;
        font-weight: bold;
      }

      &__title {
        flex: 70px 0 0;
      }

      &__content {
        flex: auto;
      }

      &__img {
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
    }
  }

  &__select,
  &__textarea,
  &__upload {
    margin-bottom: 20px;

    &__title {
      text-align: left;
      margin-bottom: 5px;
    }

    &__content {
      width: 100%;
    }
  }

  &__textarea {
    &__content {
      border: 1px solid var(--color-font-light);
      border-radius: 5px;
      font-size: 16px;
      padding: 15px;
      min-height: 180px;
      resize: none;

      &::placeholder {
        color: var(--color-placeholder);
      }

      &:focus,
      &:focus-visible {
        border-color: var(--color-main);
        outline: none;
      }
    }
  }

  &__upload {
    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 8px;

      & > * {
        min-height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-main-background);
        border-radius: 5px;
        cursor: pointer;
      }

      &__img {
        img {
          width: 100%;
        }
      }

      // & > *:before {
      //   content: "";
      //   display: block;
      //   height: 0;
      //   width: 0;
      //   padding-bottom: calc(3/4 * 100%);
      // }
    }
  }
}
</style>
<style lang="scss">
.appeal__select__content {
  .el-input__inner {
    font-size: 16px;
    border: 1px solid var(--color-font-light);

    &:focus,
    &:hover {
      border-color: var(--color-main);
    }

    &::placeholder {
      color: var(--color-placeholder);
    }
  }

  .el-select .el-input.is-focus .el-input__inner {
    border-color: var(--color-main);
  }

  .el-icon-arrow-up::before {
    content: url("../assets/images/public/ic_input_dropdown_arrow_up.png");
    vertical-align: -webkit-baseline-middle;
  }
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: var(--color-main);
}
</style>
